// Here you can add other styles
.dropdown-item {
  cursor: pointer;
}

.dashboardCards .card {
  cursor: pointer;

  .card-body {
    padding-bottom: 1rem !important;
  }
}

.wrapper.bg-light {
  background: #ebedef !important;
}

.bg-primary {
  background-color: #17505c !important;
}

.btn-primary {
  background-color: #247081;
  border-color: #0e2e34;
}

.btn-primary:hover {
  background-color: #17505c;
  border-color: #0e2e34;
}

.btn-primary.active {
  background-color: #247081;
  border-color: #0e2e34;
}

.cardTitle {
  font-weight: 700;
  color: #17505c;
}

.login-page .card,
.register-page .card {
  border-color: #2470815c;
}

.cardTitle + p {
  font-weight: 600;
  color: #17505c !important;
  opacity: 80%;
  font-size: 16px !important;
}

.sidebar {
  background: #17505c;
}

.headUserBox {
  display: flex;
  align-items: center;

  &__name {
    font-size: 16px;
    margin: 0;
    color: #17505c;
    font-weight: 700;
  }
}

.dropdown-menu {
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 22%);
  z-index: 11;
  border: 1px solid #ccc !important;
}

.headUserBox .avatar {
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 22%);
  z-index: 11;
  border: 1px solid #ccc !important;
}

.breadcrumb {
  a {
    color: #247081;
    font-weight: 500;
    text-decoration: none;
  }
}

.bg-darkGreen {
  background-color: #17505c !important;
}

.border-darkGreen {
  border-color: #17505c !important;
}

.bg-white {
  background-color: #fff !important;
}

.border-white {
  border-color: #fff !important;
}

.text-darkGreen {
  color: #17505c !important;
}

.text-white {
  color: #fff !important;
}

.font-bold {
  font-weight: 600 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.form-switch .form-check-input:checked {
  border-color: #17505c !important;
  background-color: #17505c !important;
}

.dashboardPage {
  min-height: 100vh !important;
  position: relative;

  &__inner {
    // padding: 50px 25px;
    margin: 30px;
  }

  &__root {
    min-height: 100vh !important;
    position: relative;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__title {
    font-weight: 700;
    color: #17505c;
    font-size: 30px;
    margin: 0;
    line-height: 38px;
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    a {
      color: #247081;
    }
  }
}

table {
  border-collapse: separate !important;
  border-spacing: 0 12px !important;

  td,
  th {
    border: none !important;
    padding: 8px 15px !important;
    vertical-align: middle;

    & > div {
      margin-bottom: 0 !important;
    }
  }

  thead {
    tr th {
      background-color: transparent !important;
      font-size: 16px;
      color: #000 !important;
    }
  }

  tbody {
    tr {
      box-shadow: 0px 0px 8px 0px rgb(12 27 30 / 20%);
      border-radius: 15px !important;
      border: none;
      overflow: hidden;

      td,
      th {
        background-color: #fff;
      }

      &:nth-child(odd) {
        td,
        th {
          background-color: rgb(36 112 129 / 11%);
        }
      }

      td:first-child {
        border-radius: 15px 0 0 15px;
      }

      td:last-child {
        border-radius: 0 15px 15px 0;
      }
    }
  }
}

.actionIconBtn {
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    height: 28px;
    width: 28px;
    border: 2px solid #247081;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      color: #247081;
    }
  }
}

.tutorialDetailsModule {
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
  border-radius: 15px !important;
  background-color: #fff;
  border: 2px solid #fff;
  overflow: hidden;

  &__header {
    padding: 18px 25px;
    border-bottom: 1px solid rgb(190 203 209);
    background: rgb(23 80 92 / 10%);

    p {
      font-size: 16px;
      color: #666;
      margin: 0;
    }
  }

  &__title {
    font-size: 28px;
    color: #17505c;
    font-weight: 700;
    margin: 0 0 5px;
  }
}

.tutorialDetailsCard {
  box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 11%);
  border: none !important;
  border-radius: 15px !important;
  overflow: hidden;
  height: fit-content;

  &__title {
    font-weight: 700;
    font-size: 20px;
    margin: 0 0 20px;
    color: #247081;
  }
}

.tutorialDetailsList {
  padding: 30px;

  &__item {
    font-size: 14px;
    padding: 2px 0;
    display: flex;
    align-items: center;
    margin: 0 !important;
    width: calc(100% - 65px);
  }

  &__title {
    font-weight: 700;
    min-width: 150px;
    display: inline-block;
    color: #12404e;
  }

  .accordion {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 22px;

    .accordion-header {
      button {
        font-weight: 600;
        color: #17505c;
        font-size: 18px;
        margin: 0;
        line-height: 22px;
        display: block;
        padding-right: 60px;
        text-align: right;
        &:focus {
          box-shadow: none !important;
          border: none !important;
        }
        &::after {
          position: absolute;
          right: 20px;
        }
      }
    }

    .accordion-collpase {
      border-top: 1px solid rgb(190, 203, 209);
    }

    .accordion-body {
      position: relative;
    }

    .actionIconBtn {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
}

.classFormBtn {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.alertModel {
  padding: 12px;
  max-width: 400px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  width: 100%;
}

.lassonList-head {
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.lassonList {
  max-height: 290px;
  overflow-y: auto;
  position: relative;
  margin-top: 18px;
  padding: 0 3px;

  thead {
    position: sticky;
    background: #fff;
    left: 0;
    right: 0;
    z-index: 1;
    top: 0;
    th {
      white-space: nowrap;
      padding: 2px 10px !important;
    }
  }
  .tutorialDetailsList__item {
    width: 100% !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.lorderModule {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  background: #ffffff82;
}

.text-white {
  color: white !important;
}

.text-offWhite {
  color: #ddd !important;
}

.errorMsg {
  font-size: 14px;
  color: red;
  line-height: 20px;
  margin: 8px 0 0 !important;
}

.custom-search {
  max-width: 510px;
  margin-bottom: 25px;
  position: relative;
  & > button {
    bottom: 0;
    position: absolute;
    right: 0;
    height: 38px;
    width: 38px;
    box-sizing: border-box;
    background-size: 12px;
  }
}

.massageList {
  // display: grid;
  grid-template-columns: 1fr 1fr;

  // gap: 25px;
  &__checkbox {
    // display: grid;
    // gap: 8px;
  }

  .massageList__checkbox {
    padding: 15px;
    max-height: 670px;
    height: 100%;
    overflow: auto;
    .form-check {
      margin-bottom: 10px;
      color: #6c757d;
      position: relative;
      .form-check-input {
        position: relative;
        z-index: 9;
        border: 1px solid #17505c;
      }
      .form-check-input:checked {
        background-color: #17505c;
        box-shadow: unset;
      }
    }
  }

  .col-12 > div {
    background: #fff !important;
    box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 8%);
    border: 1px solid #fff;
    border-radius: 10px !important;
    padding: 25px !important;

    &.massageList__checkbox {
      margin-bottom: 20px;
    }
  }
}

.password-form {
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  padding: 30px;
  box-shadow: 0 0 3px 0px #ccccccad;

  .input-group {
    margin-bottom: 30px;

    .input-group-text {
      width: 30%;
    }
  }
}

.halfChart {
  max-width: 350px;
  margin: 0 auto;
}

.tutorial-progress-report {
  background-color: transparent !important;
  border: none !important;

  .halfChart {
    max-width: 70px;
    margin: 0;
  }
}

.spinner-border {
  border: var(--bs-spinner-border-width) solid #17505c !important;
  border-right-color: transparent !important;
}

.modal-title {
  color: #17505c;
}

div.vr:empty {
  display: none;
}

.dashboardCards {
  .card-body > div {
    width: 100%;
  }

  &-dataList {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;

    &-no {
      font-weight: 600 !important;
      font-size: 1.5rem !important;
      line-height: 1.5;
      display: block;
    }

    & > div {
      transition: all 0.2s ease;

      &:first-child:hover {
        color: #000;
      }

      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
        transition: all 0.2s ease;

        &:hover {
          color: #000;
        }
      }
    }
  }

  .card {
    height: calc(100% - 1.5rem);
    margin-bottom: 20px;
    height: calc(100% - 20px) !important;
  }

  &-small {
    .card {
      transition: all 0.2s ease;
      background: #fff !important;
      box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
      border: 1px solid #fff;
      overflow: hidden;

      div {
        color: #000;
      }

      .card-body {
        border-left: 6px solid #1e89a1;
        padding: 25px !important;
      }

      .dashboardCards-dataList-head {
        display: flex;
        align-items: center;
        gap: 12px;
        justify-content: space-between;
        min-height: 45px;
        font-size: 22px;
        color: #17505c;
        font-weight: 700;
        transition: all 0.2s ease;

        .dashboardCards-dataList-no {
          border: 2px solid #fff;
          font-size: 22px !important;
          height: 42px;
          width: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background: #d2e7eb;
          line-height: 24px !important;
          font-weight: 600 !important;
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: 42px;
          transition: all 0.2s ease;
        }
      }

      .dashboardCards-dataInnerList {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px 20px;

        div {
          font-size: 15px;
          transition: all 0.2s ease;
          color: #17505c;
          line-height: 20px;
          font-weight: 400;

          .dashboardCards-dataList-no {
            font-size: 14px !important;
            height: 20px;
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: #d2e7eb;
            line-height: 20px !important;
            font-weight: 600 !important;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 20px;
            transition: all 0.2s ease;
          }

          &:hover {
            color: #1e89a1;
            text-decoration: underline;

            .dashboardCards-dataList-no {
              background: #17505c;
              color: #fff;
            }
          }
        }
      }

      &:hover {
        border: 1px solid #1e89a1;
        box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.15);

        .dashboardCards-dataList-head {
          color: #1e89a1;

          .dashboardCards-dataList-no {
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
}

.userProgressList {
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #eee;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0;

    .chart-wrapper {
      width: 110px;
    }
  }

  &-itemTitle {
    color: #12404e;
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 12px;

    b {
      font-weight: 700;
      color: #17505c;
    }
  }

  &-itemInfo {
    width: calc(100% - 120px);

    p {
      color: #12404e;
      font-size: 14px;
      opacity: 80%;
      margin: 0;
    }
  }
}

.visitedVideoTitle {
  font-weight: 700;
  color: #17505c;
  font-size: 24px;
  margin: 0;
  line-height: 38px;
}

.visitedVideoList {
  padding-left: 25px;

  &-item {
    cursor: pointer;
    margin-bottom: 5px;
    transition: all 0.2s ease;

    &.active {
      color: #408a99;
      font-weight: 500;
    }

    &:hover {
      color: #408a99;
      font-weight: 500;
      text-decoration: underline;
    }
  }
}

.table-responsive {
  overflow-x: auto;
  padding: 0 5px;

  table {
    td,
    th {
      white-space: nowrap;

      button {
        padding: 5px 12px;
        font-size: 14px;
      }
    }
  }
}

.blankDataModule {
  padding: 15px;
  text-align: center;
  color: #235964;
  font-weight: 500;
  border: 1px solid #e7edee;
  border-radius: 10px;
}

.settingFormCard {
  background: #fff !important;
  box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 8%);
  border: 1px solid #fff;
  border-radius: 10px !important;
  padding: 32px !important;

  button {
    min-width: 110px;
  }
}
.search-box {
  input {
    // padding-right: 30px !important;
    border-radius: 0;
  }
  .btn-close {
    // position: absolute;
    // right: 10px;
    // top: 50%;
    // transform: translate(0, -50%);
    background-size: 15px;
    position: unset;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    height: 30px;
    width: 30px;
    transform: unset;
    &:focus {
      box-shadow: unset;
    }
    &:focus-visible {
      outline: none;
    }
  }
}
.form-control {
  &:focus {
    box-shadow: unset !important;
  }
}

.addModel {
  pointer-events: none;
  cursor: default;
  & + .modal-backdrop {
    pointer-events: none;
    cursor: default;
  }
}
@media only screen and (max-width: 1024px) {
  .tutorialDetailsList .accordion {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 767px) {
  .massageList .col-12 > div.massageList__checkbox {
    height: auto;
  }
}

@media only screen and (max-width: 600px) {
  .table-responsive {
    table {
      button {
        padding: 5px 12px;
        font-size: 14px;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .login-page {
    .card {
      width: 100% !important;
    }
  }
}

